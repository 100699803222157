import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import BlogPost from "../components/BlogPost"

function Blog(props) {
  const { data } = props
  const posts = data.allGhostPost.edges

  return (
    <div>
      {posts.map(({ node }) => {
        return (
          <Wrapper key={node.id}>
            <BlogPost post={node} toTitlePath={node.slug} />
          </Wrapper>
        )
      })}
    </div>
  )
}

const Wrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing[10]};
  padding-bottom: ${({ theme }) => theme.spacing[10]};
  ${({ theme }) => theme.device.sm} {
    padding-top: ${({ theme }) => theme.spacing[8]};
    padding-bottom: ${({ theme }) => theme.spacing[8]};
  }
  ${({ theme }) => theme.device.md} {
    padding-top: ${({ theme }) => theme.spacing[12]};
    padding-bottom: ${({ theme }) => theme.spacing[6]};
  }
`

export default Blog

// TODO: use tags to filter, filter: { tags: { elemMatch: { name: { eq: "mission" } } } }
export const postQuery = graphql`
  query {
    allGhostPost(sort: { fields: published_at, order: DESC }) {
      edges {
        node {
          slug
          id
          title
          excerpt
          reading_time
          published_at(formatString: "DD MMMM YYYY")
          authors {
            id
            name
          }
          feature_image
          html
        }
      }
    }
  }
`
